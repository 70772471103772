import { Breadcrumb, Button, Card, Form, Icon, Input, message } from "antd"
import { gql } from "apollo-boost"
import { Link } from "gatsby"
import qs from "query-string"
import React from "react"
import { Mutation, Query } from "react-apollo"
import Layout from "../../components/layout"
import SEO from "../../components/seo"

const formItemLayoutWithLabel = {
  labelCol: { xs: { span: 24 }, sm: { span: 8 } },
  wrapperCol: { xs: { span: 24 }, sm: { span: 16 } },
}

const formItemLayoutWithoutLabel = {
  wrapperCol: {
    xs: { span: 24, offset: 0 },
    sm: { span: 16, offset: 8 },
  },
}

const UPDATE_ITEM_CATEGORY = gql`
  mutation updateMarketplace($id: Int, $changes: mbt_marketplaces_set_input) {
    update_mbt_marketplaces(where: { id: { _eq: $id } }, _set: $changes) {
      returning {
        name
      }
    }
  }
`

const MarketplaceForm = Form.create({
  mapPropsToFields: ({ marketplace = {} }) => ({
    name: Form.createFormField({ value: marketplace.name }),
  }),
})(({ marketplace, form, loading: queryLoading }) => (
  <Mutation mutation={UPDATE_ITEM_CATEGORY}>
    {(updateMarketplaces, { loading }) => {
      const disabled = queryLoading || loading
      return (
        <Form
          {...formItemLayoutWithLabel}
          onSubmit={e => {
            e.preventDefault()
            form.validateFieldsAndScroll(async (errors, changes) => {
              if (!errors) {
                try {
                  await updateMarketplaces({
                    variables: { id: marketplace.id, changes },
                  })
                  form.setFieldsInitialValue(changes)
                  message.success("Successfully edited marketplace")
                } catch (err) {
                  message.error("Failed to edit marketplace")
                }
              }
            })
          }}
        >
          <Form.Item label="Name">
            {form.getFieldDecorator("name", {
              rules: [
                { required: true, name: "Name is required", whitespace: true },
              ],
            })(<Input disabled={disabled} />)}
          </Form.Item>
          <Form.Item {...formItemLayoutWithoutLabel}>
            <Button
              type="primary"
              disabled={!form.isFieldsTouched()}
              htmlType="submit"
              loading={loading}
            >
              Save
            </Button>
          </Form.Item>
        </Form>
      )
    }}
  </Mutation>
))

const GET_MARKETPLACE = gql`
  query getMarketplace($id: Int!) {
    mbt_marketplaces_by_pk(id: $id) {
      id
      name
    }
  }
`

export default ({ location }) => {
  const { id } = qs.parse(location.search)
  return (
    <Layout>
      <SEO title="Marketplace" />
      <Breadcrumb style={{ margin: "16px 0" }}>
        <Breadcrumb.Item>
          <Link to="/">
            <Icon type="home" />
          </Link>
        </Breadcrumb.Item>
        <Breadcrumb.Item>
          <Link to="/marketplace/">Marketplace</Link>
        </Breadcrumb.Item>
        <Breadcrumb.Item>Edit</Breadcrumb.Item>
      </Breadcrumb>
      <Card>
        <Query
          fetchPolicy="cache-and-network"
          query={GET_MARKETPLACE}
          variables={{ id }}
        >
          {({ data, loading }) => (
            <MarketplaceForm
              marketplace={data.mbt_marketplaces_by_pk}
              loading={loading}
            />
          )}
        </Query>
      </Card>
    </Layout>
  )
}
